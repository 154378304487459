import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import { media } from '@/style/helpers'
import {
  AboutPageProfileCardStoryblok,
  RichtextStoryblok,
} from '@/types/storyblok-component-types'

interface TeamProps {
  caption?: RichtextStoryblok
  profileCards?: AboutPageProfileCardStoryblok[]
}

const TeamMembers: FunctionComponent<TeamProps> = ({
  caption,
  profileCards,
}) => {
  return (
    <TeamMembersWrapper>
      <TextWrapper>
        <CaptionRichTextWrapper>
          <StoryblokRichText document={caption} />
        </CaptionRichTextWrapper>
      </TextWrapper>

      {profileCards && <TeamMemberSection profileCards={profileCards} />}
    </TeamMembersWrapper>
  )
}

const TeamMemberSection = ({
  profileCards,
}: {
  profileCards: AboutPageProfileCardStoryblok[]
}) => {
  return (
    <div>
      {profileCards.map((card) => {
        const linkedInUrl = getStoryBlokLink(card.linkedin_url)
        return (
          <TeamMemberCardDiv key={card._uid}>
            {card.image?.filename && (
              <CardImageWrapper>
                <StoryblokImage
                  sizes="(max-width: 767px) 80vw, (max-width: 1441px) 26vw, 384px"
                  style={{ borderRadius: '1rem' }}
                  image={card.image}
                />
              </CardImageWrapper>
            )}
            <TeamMemberTitle>
              <span>{card.name}</span>
              {linkedInUrl && (
                <IconWrapper
                  href={linkedInUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon fontSize="small" />
                </IconWrapper>
              )}
            </TeamMemberTitle>
            <DescriptionRichTextWrapper>
              <StoryblokRichText document={card.description} />
            </DescriptionRichTextWrapper>
          </TeamMemberCardDiv>
        )
      })}
    </div>
  )
}

const TeamMembersWrapper = styled.div`
  margin: auto;
  text-align: center;
  align-content: center;
  margin-bottom: 2rem;

  ${media.xs} {
    margin: auto;
    text-align: center;
    align-content: center;
    margin-bottom: 3rem;
  }

  ${media.sm} {
    margin: auto 5rem;
    text-align: center;
    align-content: center;
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  ${media.md} {
    margin: auto 5rem;
    text-align: center;
    align-content: center;
    margin-top: 10rem;
    margin-bottom: 9rem;
  }
`

const TextWrapper = styled.div`
  text-align: center;
  margin: auto;
  width: 75%;
  max-width: 22rem;
  margin-bottom: 2rem;

  ${media.xs} {
    max-width: 42rem;
  }

  ${media.md} {
    margin-bottom: 3rem;
  }
`

const CaptionRichTextWrapper = styled(RichTextParagraphStyles)`
  & > p:first-of-type {
    margin-top: 0 !important;
  }

  & > p:last-of-type {
    margin: 1.25rem 0px !important;
  }
`

const DescriptionRichTextWrapper = styled(RichTextParagraphStyles)`
  & > p {
    color: #666666;
    font-family: GalanoGrotesque;
    font-size: 0.8rem !important;
    text-align: center;
  }

  & > p:first-of-type {
    margin-top: 0;
    margin-bottom: 0 !important;
    color: #000000 !important;
    font-family: GalanoGrotesque;
    font-size: 0.8rem !important;
    text-align: center;
  }

  & > p:last-of-type {
    margin-bottom: 2rem;

    ${media.xs} {
      margin: auto;
      font-size: 0.8rem;
      text-align: center;
      margin-bottom: 2rem;
    }

    ${media.sm} {
      margin: auto;
      font-size: 0.8rem;
      text-align: center;
    }

    ${media.md} {
      margin: auto;
      font-size: 0.8rem;
      text-align: center;
    }
  }
`

const TeamMemberCardDiv = styled.div`
  float: none;
  display: inline-block;
  border-radius: 1rem;
  width: 85%;
  margin: auto 0.7rem;
  vertical-align: top;
  margin-bottom: 1rem;

  ${media.xs} {
    float: none;
    display: inline-block;
    border-radius: 1rem;
    width: 80%;
    margin: auto 0.7rem;
    vertical-align: top;
    margin-bottom: 1rem;
  }

  ${media.sm} {
    float: none;
    display: inline-block;
    border-radius: 1rem;
    width: 30%;
    margin: auto 0.5rem;
    vertical-align: top;
  }

  ${media.md} {
    display: inline-block;
    float: none;
    border-radius: 1rem;
    width: 30%;
    margin: auto 0.7rem;
    margin-bottom: 3rem;
    vertical-align: top;
  }
`

const CardImageWrapper = styled.div`
  margin-bottom: 0.7rem;

  ${media.xs} {
    margin-bottom: 0.7rem;
  }

  ${media.sm} {
    margin-bottom: 0.7rem;
  }

  ${media.md} {
    margin-bottom: 1.3rem;
  }
`

const TeamMemberTitle = styled.p`
  margin: auto;
  color: ${(props) => props.theme.colors.primary};
  font-family: GalanoGrotesque;
  font-size: 0.8rem;
  line-height: 1.3rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 0.18rem;

  > span {
    vertical-align: middle;
  }
`

const IconWrapper = styled.a`
  color: initial;
  vertical-align: middle;
  margin-left: 0.25rem;
  display: inline-block;
  line-height: 1;
`

export default TeamMembers
