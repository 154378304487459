import { storyblokEditable } from '@storyblok/react'
import styled from 'styled-components'
import StoryblokRichText from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import { getFontCSS } from '@/style/components/LegacyText/sizes'
import { media } from '@/style/helpers'
import { TableStoryblok } from '@/types/storyblok-component-types'

export default function Table({ blok }: { blok: TableStoryblok }) {
  return (
    <TableWrapper {...storyblokEditable(blok)}>
      <table>
        <thead>
          <tr>
            {blok.headers?.map((header) => (
              <th>
                <RichtextWrapper>
                  <StoryblokRichText document={header.data} />
                </RichtextWrapper>
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {blok.rows?.map((row) => (
            <tr>
              {row.data?.map((row) => (
                <td>
                  <RichtextWrapper>
                    <StoryblokRichText document={row.data} />
                  </RichtextWrapper>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </TableWrapper>
  )
}

const RichtextWrapper = styled.span`
  & h1 {
    ${getFontCSS('xxxl')}

    text-transform: uppercase;
    margin-bottom: 0.75rem;
    font-weight: 700;
  }

  & h2 {
    ${getFontCSS('xl')}

    text-transform: uppercase;
    margin-bottom: 0.75rem;

    font-size: 1.375rem;
    font-weight: 700;

    ${media.md} {
      font-size: 2.375rem;
    }
  }

  & h3 {
    ${getFontCSS('md')}
    font-weight: 600;
  }

  & h4,
  & h5,
  & h6,
  & b {
    font-weight: 600;
  }

  & i {
    font-style: italic;
  }

  & p {
    ${getFontCSS('md')}
  }

  & > p:first-of-type {
    margin-top: 0 !important;
  }

  & > p:last-of-type {
    margin-bottom: 0 !important;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }
`

const TableWrapper = styled.div`
  width: 100%;
  overflow-x: auto;

  table {
    th,
    td {
      text-align: left;
      padding: 0.75rem;
    }

    thead {
      background-color: #f2f4f8;
    }
    width: 100%;

    th {
      text-transform: uppercase;
      font-weight: 600;
    }

    td {
      border-top: 1px solid #dee2e6;
    }
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.primary};
    border-radius: 10px;
  }

  ::-webkit-scrollbar {
    background-color: #f2f4f8;
    width: 10px;
    height: 4px;
  }

  line-height: 18px;
  ${media.md} {
    line-height: 22px;
  }
`
