import { useImageSizes } from '../Section/Section'
import { storyblokEditable } from '@storyblok/react'
import Link from 'next/link'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import { getStoryBlokLink } from '@/helpers/getStoryBlokLink'
import { media } from '@/style/helpers'
import { hoverShadowBox } from '@/style/partials'
import {
  CardsGridItemStoryblok,
  MultilinkStoryblok,
} from '@/types/storyblok-component-types'
import Background from './assets/bkg_logo.svg'

type Props = {
  blok: CardsGridItemStoryblok
  smallerImage?: boolean
  nothingFound?: {
    link?: MultilinkStoryblok
    caption: string
    description?: string
  }
}

export default function CardsListItem({
  blok,
  nothingFound,
  smallerImage,
}: Props) {
  const sizes = useImageSizes(21.3, ['md', '44vw'])
  const link = nothingFound ? nothingFound.link : blok.link

  return (
    <Wrapper
      {...storyblokEditable(blok)}
      href={getStoryBlokLink(link)}
      legacyBehavior={false}
      showBorder={!!nothingFound}
      bgColor={!nothingFound ? 'white' : '#F2F4F8'}
    >
      <ImageContainer bgColor={nothingFound ? '#F2F4F8' : 'white'}>
        {nothingFound && <StyledBackground />}
        <LazyLoad
          bgColor1={blok.background_color_1?.color || 'transparent'}
          bgColor2={blok.background_color_2?.color || 'transparent'}
          smallerImage={smallerImage}
          paddingTop={nothingFound ? '83%' : undefined}
        >
          {nothingFound ? (
            <NothingFoundDesc>{nothingFound?.description}</NothingFoundDesc>
          ) : (
            blok.image?.filename && (
              <ImageWrapper>
                <StoryblokImage
                  image={blok.image}
                  sizes={sizes}
                  layout="responsive"
                />
              </ImageWrapper>
            )
          )}
        </LazyLoad>
      </ImageContainer>

      <CaptionWrapper>
        {nothingFound ? (
          <NothingFoundCaption>{nothingFound?.caption}</NothingFoundCaption>
        ) : (
          <Caption>{blok.caption}</Caption>
        )}
      </CaptionWrapper>
    </Wrapper>
  )
}

const Wrapper = styled(Link)<{ bgColor?: string; showBorder?: boolean }>`
  display: block;
  align-items: center;
  position: relative;
  width: calc(50% - 0.6rem);
  margin: 0.3rem;
  border-radius: 5px;
  padding: 0 1rem;
  text-align: center;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  background-color: ${({ bgColor }) => bgColor || 'transparent'};
  ${({ showBorder }) => showBorder && 'border: 2px solid #e9e9e9;'}
  ${hoverShadowBox}

  ${media.sm} {
    width: calc(33.3333% - 1rem);
    margin: 0.5rem;
    padding: 0.8rem 0.8rem 0.8rem 0.8rem;
  }

  ${media.md} {
    width: calc(20% - 1.5rem);
    margin: 0.75rem;
  }

  h3 {
    margin: 0;
  }

  p {
    margin: 1rem 0;
  }
`
const ImageContainer = styled.div<{ paddingTop?: string; bgColor?: string }>`
  flex: 1;
  line-height: 1.4;
  font-weight: 400;
  padding-top: ${({ paddingTop }) => paddingTop || 'unset'};
  background-color: ${({ bgColor }) => bgColor || 'white'};
`

const LazyLoad = styled.div<{
  bgColor1?: string
  bgColor2?: string
  smallerImage?: boolean
  paddingTop?: string
}>`
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
  margin: 1.25rem 0;
  padding-top: ${({ paddingTop }) => paddingTop || 'unset'};

  img {
    background: ${({ bgColor1, bgColor2 }) =>
      `linear-gradient(129.32deg,${bgColor1} 0%,${bgColor2} 100%)`};
    padding: ${({ smallerImage }) =>
      smallerImage ? '1.5rem !important' : 'none'};
    border-radius: 4px;
  }
`

const ImageWrapper = styled.div`
  & > span {
    width: 100% !important;
    display: inline-block !important;
  }
`

const CaptionWrapper = styled.div`
  bottom: 0;
  left: 0;
  width: 100%;

  &::after {
    margin-top: 1.3rem;
    content: '';
    display: block;
    clear: both;
  }
`

const GeneralCaption = `
  display: flex;
  width: 100%;
  justify-content: center;
  box-sizing: content-box;
`

const Caption = styled.h3`
  ${GeneralCaption}
  letter-spacing: 0em;
  line-height: 1.17;
  font-weight: 600;
  font-size: 0.8rem;

  ${media.xs} {
    font-size: 0.875rem !important;
  }

  ${media.sm} {
    font-size: 1rem !important;
  }

  ${media.lg} {
    font-size: 1.125rem !important;
  }
`

const NothingFoundDesc = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 600;
  letter-spacing: 0em;
  line-height: 1.17;

  ${media.xs} {
    font-size: 0.875rem !important;
  }
`

const NothingFoundCaption = styled.p`
  ${GeneralCaption}
  margin: 0 !important;
  text-decoration: underline ${(props) => props.theme.colors.secondary};
  text-underline-offset: 3px;
  font-weight: 600;
  line-height: 1.17;
  border-bottom-width: 2px;

  ${media.xs} {
    font-size: 0.875rem !important;
  }
`

const StyledBackground = styled(Background)`
  position: absolute;
  top: 22.5%;
  left: 25%;
  height: auto;
  width: 50%;
  * {
    fill: ${({ theme }) => theme.colors.border};
  }
`
