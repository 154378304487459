import styled from 'styled-components'
import Text from '@/style/components/LegacyText'
import RadioButton from '@/style/components/RadioButton'
import { media } from '@/style/helpers'
import { hoverShadowBox } from '@/style/partials'
import { printLocalAmount } from '@/utils/misc'

const Wrapper = styled.div<{
  hidden?: boolean
  checked?: boolean
  page?: string
}>`
  ${(props) =>
    props.hidden &&
    `
  display: none !important;
`}
  ${(props) =>
    props.checked &&
    `
    border: 2px solid ${props.theme.colors.secondary} !important;
`}
  display: flex;
  flex-direction: row;
  ${(props) =>
    !props.page &&
    `

    margin-bottom: 0.75rem;
    ${media.sm} {
      margin: 0.5rem 0.5rem;
    }


  `}

  ${(props) =>
    props.page === 'shipmentInPayback' &&
    `
  margin: 0.312rem 0;
  ${media.md} {
    margin: 0.5rem 0;
  }
  `}

  padding: 1rem;
  padding-right: 0.25rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radius.normal}px;
  text-decoration: none;
  color: inherit;
  border: 2px solid ${(props) => props.theme.colors.lightGrey};
  ${hoverShadowBox}
  &:hover {
    border: 2px solid ${(props) => props.theme.colors.secondary};
  }
  flex: 1;

  label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0.25rem;
  }

  ${media.sm} {
    padding: 1rem;

    label {
      margin-left: 1rem;
    }
  }

  ${media.md} {
    label {
      width: 100%;
    }
  }

  h2 {
    color: black;
  }

  h2 span {
    color: ${(props) => props.theme.colors.light};
  }

  svg {
    flex: 1 0 auto;
    ${(props) =>
      props.checked &&
      `
      g {
        fill: ${props.theme.colors.secondary};
      }
  `}
  }

  h3 {
    margin: 0;
    color: ${(props) => props.theme.colors.light};
    font-size: small;
    margin-top: 0.2rem;
  }
`

const PriceLabel = styled.span`
  color: ${(props) => props.theme.colors.primary} !important;
`

const Label = styled.label`
  flex: 1;
  cursor: pointer;

  span {
    margin-right: 0.5rem;
  }
`

const IconWrapper = styled.div`
  width: 49px;
  height: 49px;
  display: flex;
  align-items: center;
`

interface TransportModeButtonProps {
  onChange: (type: boolean) => void
  checked: boolean
  title: string
  note: string
  icon: JSX.Element
  fee?: number
  page?: string
  id?: string
}

export const StorageModeButton: React.FC<TransportModeButtonProps> = (
  props,
) => {
  return (
    <Wrapper checked={props.checked} page={props.page} id={props.id}>
      <IconWrapper>{props.icon}</IconWrapper>
      <Label id={`${props.id}_OPTION_LABEL`}>
        <span>
          <Text.md as="h2" weight="semibold" id={`${props.id}_TITLE`}>
            {props.title}
            {props.fee ? (
              <PriceLabel>
                {' '}
                +
                {printLocalAmount({
                  number: props.fee,
                  fractionDigits: 0,
                  removeSpaces: true,
                })}
              </PriceLabel>
            ) : null}
          </Text.md>
          <h3 id={`${props.id}_DESCRIPTION`}>{props.note}</h3>
        </span>
        <RadioButton
          id={`${props.id}_OPTION`}
          name="transportMode"
          checked={props.checked}
          value={props.checked}
          onChange={(e) => {
            props.onChange(e.target.checked)
          }}
        />
      </Label>
    </Wrapper>
  )
}
