import { Domain } from '../Domain'
import { DefaultSeo } from 'next-seo'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'
import { useRecoilState } from 'recoil'
import { customerAuthFinishedState, customerState } from '@/app/auth/auth.state'
import { guestEmailState } from '@/domains/checkout/checkout.state'
import CookiesConsent from '@/domains/cookies/CookiesAgreement'
import useRegionCode from '@/helpers/useRegionCode'
import { ERegionCode } from '@/types/gql/graphql'
import { getDefaultSeoProps, regions } from '~/next-seo.config'

interface Props {
  children?: React.ReactNode
}

export default function PageProvider({ children }: Props) {
  const router = useRouter()
  const [customer] = useRecoilState(customerState)
  const [customerAuthFinished] = useRecoilState(customerAuthFinishedState)
  const [guestEmail] = useRecoilState(guestEmailState)

  useEffect(() => {
    if (!customerAuthFinished || customer || guestEmail) return

    if (router.route === '/checkout/overview') {
      router.replace('/checkout/items')
    } else if (router.route.includes('/profile')) {
      router.replace('/')
    }
  }, [customer, customerAuthFinished, router, guestEmail])

  const regionCode = useRegionCode()
  const { asPath: path, locale } = useRouter()

  return (
    <>
      <DefaultSeo {...getDefaultSeoProps(regionCode, path, locale as string)} />

      <Head>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />

        <link href="/api/manifest.webmanifest" rel="manifest" />
        <link
          rel="icon"
          type="image/png"
          href="/assets/favicon-96x96.png"
          sizes="96x96"
        />
        <link rel="icon" type="image/svg+xml" href="/assets/favicon.svg" />
        <link rel="mask-icon" href="/assets/favicon.svg" color="black" />
        <link rel="shortcut icon" href="/assets/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/apple-touch-icon.png"
        />
        <meta name="apple-mobile-web-app-title" content="CASHY" />

        <WebSiteJsonLD regionCode={regionCode} />
      </Head>
      <Domain />
      <CookiesConsent />
      {children}
    </>
  )
}

function WebSiteJsonLD({ regionCode }: { regionCode: ERegionCode }) {
  const data = useMemo(
    () =>
      JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        name: 'CASHY',
        url: regions[regionCode].baseUrl,
      }),
    [regionCode],
  )

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: data }}
    />
  )
}
