import { ImageLoader } from 'next/image'
import config from '@/../tailwind.config'
import { useStoryblokImageLoader } from '@/app/common/components/StoryblokImage/StoryblokImage'
import StoryblokRichText, {
  RichTextParagraphStyles,
} from '@/app/common/components/StoryblokRichText/StoryblokRichText'
import {
  AssetStoryblok,
  CarPawnHeroStoryblok,
} from '@/types/storyblok-component-types'
import LoanAmountCard from './LoanAmountCard'

export default function CarPawnHero({ blok }: { blok: CarPawnHeroStoryblok }) {
  const loader = useStoryblokImageLoader({})

  // required properties missing as blok is not fully configured
  if (!blok.mobile_image || !blok.tablet_image || !blok.desktop_image) {
    return
  }

  return (
    <div
      className="relative w-full p-4 md:flex md:flex-row-reverse md:gap-x-12 h-[--mobile-height] xs:h-[--tablet-height] md:h-[--desktop-height]"
      style={
        {
          '--mobile-height': blok.mobile_height + 'px',
          '--tablet-height': blok.tablet_height + 'px',
          '--desktop-height': blok.desktop_height + 'px',
        } as React.CSSProperties
      }
    >
      {/** Subtract half the height of the loan amount card to create overlap effect */}
      <div className="h-[calc(100%-99px)] relative md:h-full md:flex-1">
        <picture
          key={`${blok.mobile_image.id}-${blok.tablet_image.id}-${blok.desktop_image.id}`}
        >
          <source
            media={`(min-width: ${config.theme.screens.md})`}
            srcSet={buildSrcSet(blok.desktop_image, loader, 0.5)}
          />
          <source
            media={`(min-width: ${config.theme.screens.xs})`}
            srcSet={buildSrcSet(blok.tablet_image, loader)}
          />
          <source srcSet={buildSrcSet(blok.mobile_image, loader)} />

          <img
            className="rounded-xl size-full object-cover"
            sizes={`(max-width: ${Number.parseInt(config.theme.screens.md) - 1}px) 100vw, 50vw`}
            alt={blok.desktop_image.alt}
            title={blok.desktop_image.title}
            src={blok.mobile_image.filename}
          />
        </picture>

        <div
          className="absolute inset-0 md:hidden"
          style={
            {
              backgroundImage:
                'linear-gradient(to bottom, var(--gradient-top) 20%, var(--gradient-bottom))',
              '--gradient-bottom':
                blok.mobile_gradient_color_bottom?.color || 'rgb(0 0 0 / 0)',
              '--gradient-top':
                blok.mobile_gradient_color_top?.color || 'rgb(0 0 0 / 0)',
            } as React.CSSProperties
          }
        />
      </div>

      <div className="absolute inset-4 flex flex-col justify-end items-center md:static md:flex-1 md:inset-0 md:justify-center">
        <RichTextParagraphStyles className="text-center md:hidden">
          <StoryblokRichText document={blok.mobile_content} />
        </RichTextParagraphStyles>

        <RichTextParagraphStyles className="hidden md:block">
          <StoryblokRichText document={blok.desktop_content} />
        </RichTextParagraphStyles>

        <LoanAmountCard
          ctaText={blok.button_label}
          className="mt-1 md:absolute md:mt-0 md:right-[calc(25%-(320px-1rem+1.5rem)/2)]"
        />
      </div>
    </div>
  )
}

// TODO CAS-4027: replace with getImageProps API
// https://nextjs.org/docs/14/app/api-reference/components/image#getimageprops
function buildSrcSet(
  image: AssetStoryblok,
  loader: ImageLoader,
  multiplier = 1,
) {
  return deviceSizes
    .map((w) => {
      const width = w * multiplier
      return `${loader({ src: image.filename, width, quality: 90 })} ${width}w`
    })
    .join(', ')
}

const deviceSizes = [640, 750, 828, 1080, 1200, 1920, 2048, 3840]
