import { Box } from '@material-ui/core'
import { motion } from 'framer-motion'
import React, { FunctionComponent, useState } from 'react'
import { useTheme } from 'styled-components'
import { AnimatePresence } from '@/utils/animatePresence'

interface CollapseProps {
  title: string
  children: React.ReactNode
}

const Collapse: FunctionComponent<CollapseProps> = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(false)
  const theme = useTheme()

  return (
    <>
      <AnimatePresence>
        <Box display="flex" justifyContent="center">
          <Box
            display="flex"
            justifyContent="center"
            style={{ cursor: 'pointer' }}
            mt="1rem"
            fontSize="0.875rem"
            paddingBottom="0.25rem"
            component="span"
            color="#666"
            borderBottom={`2px ${theme.colors.secondary} solid`}
            key="question"
            onClick={() => setIsOpen(!isOpen)}
          >
            {title}
          </Box>
        </Box>

        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
            style={{ overflow: 'hidden' }}
          >
            {children}
          </motion.section>
        )}
      </AnimatePresence>
    </>
  )
}

export default Collapse
