import { cn } from '../../utils/cn'
import { printLocalAmount } from '../../utils/misc'
import useTranslation from 'next-translate/useTranslation'
import { useState } from 'react'

export interface LoanAmountCardProps {
  className?: string
  ctaText: string
}

export default function LoanAmountCard(props: LoanAmountCardProps) {
  const { t } = useTranslation()

  const [inputId] = useState(() => `loan-amount-${id++}`)
  const [loanAmount, _setLoanAmount] = useState(5000)
  const [inputValue, setInputValue] = useState(() =>
    printLocalAmount({
      number: loanAmount,
      fractionDigits: 0,
      removeSpaces: true,
    }),
  )

  const setLoanAmount = (amount: number) => {
    _setLoanAmount(amount)
    setInputValue(
      printLocalAmount({
        number: amount,
        fractionDigits: 0,
        removeSpaces: true,
      }),
    )
  }

  const sliderValue = loanAmountToSliderValue(loanAmount)

  return (
    <form
      className={cn(
        'p-3 rounded-xl flex flex-col items-center shadow-xl gap-y-2 max-w-80 bg-white',
        props.className,
      )}
    >
      <label
        className="text-primary font-semibold leading-[140%]"
        htmlFor={inputId}
      >
        {t('common:choose_loan_amount')}
      </label>

      <input
        className="w-full px-6 border border-neutral-900 rounded-xl text-brand-600 text-5xl h-14 pb-0.5 font-bold text-center"
        id={inputId}
        name="loan-amount"
        value={inputValue}
        onFocus={(event) => {
          event.target.setSelectionRange(0, event.target.value.length - 1)
        }}
        onKeyDown={(event) => {
          const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight']
          if (
            event.ctrlKey ||
            event.metaKey ||
            allowedKeys.includes(event.key)
          ) {
            return
          }

          if (
            event.currentTarget.value.endsWith('€') &&
            event.currentTarget.value.length ===
              event.currentTarget.selectionEnd
          ) {
            if (
              event.currentTarget.selectionStart ??
              0 >= event.currentTarget.selectionEnd - 1
            ) {
              event.preventDefault()
            } else {
              event.currentTarget.selectionEnd--
            }
          }

          if (!event.key.match(amountInputAllowedRegEx)) {
            event.preventDefault()
          }
        }}
        onPaste={(event) => {
          const text = event.clipboardData.getData('text')

          if (!text.match(amountInputAllowedRegEx)) {
            event.preventDefault()
          }
        }}
        onChange={(event) => {
          setInputValue(event.target.value)
        }}
        onBlur={(event) => {
          const value = Number.parseFloat(
            event.target.value.replaceAll('.', '').replaceAll(',', '.'),
          )

          if (Number.isNaN(value)) {
            return
          }

          const cappedAmount = Math.max(500, Math.min(200_000, value))
          setLoanAmount(
            sliderValueToLoanAmount(loanAmountToSliderValue(cappedAmount)),
          )
        }}
      />

      {/**
        500€ - 5,000€: 45x 100€ step
        5,000€ - 20,000€: 30x 500€ step
        20,000€ - 200,000€: 180x 1,000€ step
        => 255 steps in total
      */}
      <input
        type="range"
        name="loan-amount-step"
        className="w-full car-hero-slider"
        aria-hidden
        tabIndex={-1}
        min={0}
        max={255}
        value={sliderValue}
        style={
          {
            '--slider-value-percent': `${((sliderValue / 255) * 100).toFixed(2)}%`,
          } as React.CSSProperties
        }
        onChange={(event) => {
          setLoanAmount(sliderValueToLoanAmount(event.target.valueAsNumber))
        }}
      />

      <button className="p-3 rounded-xl bg-fill-brand-primary text-on-primary font-semibold mt-1 w-full">
        {props.ctaText}
      </button>
    </form>
  )
}

let id = 1
const amountInputAllowedRegEx = /^[\d.]+$/

function loanAmountToSliderValue(loanAmount: number): number {
  if (loanAmount >= 20_000) {
    return 45 + 30 + Math.ceil(loanAmount / 1_000) - 20
  } else if (loanAmount >= 5_000) {
    return 45 + Math.ceil(loanAmount / 500) - 10
  } else {
    return Math.ceil(loanAmount / 100) - 5
  }
}

function sliderValueToLoanAmount(sliderValue: number): number {
  let loanAmount = 500 + Math.min(sliderValue, 45) * 100
  if (sliderValue <= 45) {
    return loanAmount
  }

  sliderValue -= 45
  loanAmount += Math.min(sliderValue, 30) * 500
  if (sliderValue <= 30) {
    return loanAmount
  }

  sliderValue -= 30
  return loanAmount + sliderValue * 1_000
}
