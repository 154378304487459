import useTranslation from 'next-translate/useTranslation'
import styled from 'styled-components'
import StoryblokImage from '@/app/common/components/StoryblokImage/StoryblokImage'
import { media } from '@/style/helpers'
import { AssetStoryblok } from '@/types/storyblok-component-types'

export const Hero = ({
  leftImage,
  rightImage,
  backgroundColor,
}: {
  leftImage?: AssetStoryblok
  rightImage?: AssetStoryblok
  backgroundColor?: string
}) => {
  const { t } = useTranslation()
  return (
    <HeroWrapper $backgroundColor={backgroundColor}>
      <InnerWrapper>
        <LeftImageWrapper>
          {leftImage?.filename && (
            <StoryblokImage
              sizes="(max-width: 331px) 41vw, (max-width: 992px) 137px, 400px"
              layout="fill"
              objectFit="cover"
              image={leftImage}
            />
          )}
        </LeftImageWrapper>
        <RightImageWrapper>
          {rightImage?.filename && (
            <StoryblokImage
              sizes="(max-width: 331px) 69vw, (max-width: 992px) 232px, 467px"
              layout="fill"
              objectFit="cover"
              image={rightImage}
            />
          )}
        </RightImageWrapper>
        <HeroTitle>
          <h1>{t('blog:hero_title')}</h1>
          <p>{t('blog:hero_description')}</p>
        </HeroTitle>
      </InnerWrapper>
    </HeroWrapper>
  )
}

const HeroWrapper = styled.div<{ $backgroundColor?: string }>`
  background-color: ${(props) =>
    props.$backgroundColor ?? props.theme.colors.primary};
  overflow: hidden;
  height: 172px;
  min-height: 172px;
  margin-bottom: 30px;

  ${media.md} {
    margin-bottom: 40px;
    min-height: 388px;
    height: 388px;
    padding-right: 15px;
    padding-left: 15px;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  bottom: 0;
  height: 100%;
`

const LeftImageWrapper = styled(ImageWrapper)`
  width: 137px;
  margin-left: -37px;

  ${media.md} {
    margin-left: 15px;
    width: 400px;
  }
`

const RightImageWrapper = styled(ImageWrapper)`
  width: 232px;

  ${media.md} {
    margin-top: 33px;
    width: 467px;
    height: 92% !important;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 100%;

  margin-left: 0px;
  margin-right: 0xp;

  ${media.md} {
    margin-left: 5%;
    margin-right: 5%;
  }
`

const HeroTitle = styled.div`
  position: absolute;
  top: 60px;
  width: 100%;
  text-align: center;
  color: #ffffff;

  ${media.md} {
    top: 167px;
  }
`
