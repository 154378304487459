import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { media } from '@/style/helpers'

interface StepsProps {
  year: string
  children: React.ReactNode
}

const Steps: FunctionComponent<StepsProps> = ({ children, year }) => {
  return (
    <StepsWrapper>
      <YearText>{year}</YearText>
      {children}
    </StepsWrapper>
  )
}

const YearText = styled.p`
  color: ${(props) => props.theme.colors.primary};
  font-family: GalanoGrotesque;
  font-size: 1.6rem;
  letter-spacing: 0.44px;
  text-align: center;
  text-decoration: underline;
  margin-bottom: 1.5rem;
  font-weight: 600;
  margin-top: -1.3rem;

  ${media.sm} {
    margin-top: -3.3rem;
  }
`

const StepsWrapper = styled.div`
  margin-top: 3rem;

  ${media.sm} {
    margin-top: 5rem;
  }
`

export default Steps
